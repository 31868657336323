// 商品详情
const CommodityDetail = () => import('@home/commodity_detail/index.vue');
// 确认订单
const ConfirmOrder = () => import('@home/confirm_order/index.vue');
// 实名认证
const Verified = () => import('@home/verified/index.vue');
// 支付界面
const Pay = () => import('@home/pay/index.vue');
// 支付结果
const PayState = () => import('@home/pay_state/index.vue');
// 店铺
const ShopInfo = () => import('@home/shop_info/index.vue');

const HomeRouter = [
  {
    path: '/commodity_detail',
    name: '商品详情',
    component: CommodityDetail,
  },
  {
    path: '/confirm_order',
    name: '确认订单',
    component: ConfirmOrder,
  },
  {
    path: '/verified',
    name: '实名认证',
    component: Verified,
  },
  {
    path: '/pay',
    name: '支付订单',
    component: Pay,
  },
  {
    path: '/pay_state',
    name: '支付结果',
    component: PayState,
  },
  {
    path: '/shop_info',
    name: '店铺信息',
    component: ShopInfo,
  },
];

export default HomeRouter;
