import {
  createRouter, createWebHashHistory, Router,
} from 'vue-router';
import { getToken } from '@/utils/auth';
import routes from './routers';

interface Window {
  aplus_queue: any
}
declare const window: Window & { aplus_queue: any };
const router: Router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  window.aplus_queue.push({
    action: 'aplus.sendPV',
    arguments: [{ is_auto: false }],
  });
  const { name } = to;
  document.title = String(name);
  if (getToken()) {
    next();
  } else if (to.path === '/my') {
    // if (whiteList.includes(to.path)) {
    //   next();
    //   return;
    // }
    next('/login');
    return;
  } else {
    next();
  }
});

export default router;
