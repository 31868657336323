import { RouteRecordRaw } from 'vue-router';
import Home from '@home/Home.vue';
import User from '@user/User.vue';
import AddressLists from '@user/address/AddressLists.vue';
import AddressAdd from '@user/address/AddressAdd.vue';
import PersonalInfo from '@user/PersonalInfo.vue';
import Contacts from '@user/Contacts.vue';
import Classify from '../views/classify/Classify.vue';
/* home文件路由 */
import HomeRouter from './HomeRouter';
/** user文件路由 */
import MyRouter from './MyRouter';

import BindCard from './bindCard';
import Activity from './activity';
import Repayment from './repayment';

const Login = () => import('../views/login/Login.vue');
const BillList = () => import('../views/bill/BillList.vue');
const BillMiddle = () => import('../views/bill/BillMiddle.vue');
const BillDetail = () => import('../views/bill/BillDetail.vue');
const OrderSuccess = () => import('../views/order/OrderSuccess.vue');
const Coupon = () => import('../views/user/coupon/Coupon.vue');
const BankCard = () => import('../views/user/bankCard/BankCard.vue');
const AddCBank = () => import('../views/user/bankCard/AddBank.vue');
const Zone = () => import('../views/goods/zone/Zone.vue');
const InfoSupplement = () => import('@user/InfoSupplement.vue');
const NoFind = () => import('../views/nofind/NoFind.vue');
const Register = () => import('../views/login/Register.vue');

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/home',
  },
  {
    path: '/home',
    name: '首页',
    component: Home,
  },
  {
    path: '/login',
    name: '登录',
    component: Login,
  },
  {
    path: '/register',
    name: '登录/注册',
    component: Register,
  },
  {
    path: '/classify',
    name: '分类',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/classify/Classify.vue'),
    component: Classify,
  },
  {
    path: '/my',
    name: '我的',
    component: User,
  },
  {
    path: '/myinfo',
    name: '个人信息',
    component: PersonalInfo,
  },
  {
    path: '/mysupplement',
    name: '信息补全',
    component: InfoSupplement,
  },
  {
    path: '/mycoupon',
    name: '优惠券列表',
    component: Coupon,
  },
  {
    path: '/mybank',
    name: '我的银行卡',
    component: BankCard,
  },
  {
    path: '/addbank',
    name: '添加银行卡',
    component: AddCBank,
  },
  {
    path: '/address/add',
    name: '添加收货地址',
    component: AddressAdd,
  },
  {
    path: '/address/edit/:id',
    name: '编辑收货地址',
    component: AddressAdd,
  },
  {
    path: '/address',
    name: '地址管理',
    component: AddressLists,
  },
  {
    path: '/contact',
    name: '紧急联系人',
    component: Contacts,
  },
  {
    path: '/bill_list',
    name: '账单承接页',
    component: BillMiddle,
  },
  {
    path: '/bill',
    name: '账单列表',
    component: BillList,
  },
  {
    path: '/bill/detail',
    name: '账单详情',
    component: BillDetail,
  },
  {
    path: '/order/success',
    name: '下单成功',
    component: OrderSuccess,
  },
  {
    path: '/zone/:id',
    name: '商品专区',
    component: Zone,
  },
  ...HomeRouter,
  ...MyRouter,
  ...BindCard,
  ...Activity,
  ...Repayment,
  {
    path: '/:pathMatch(.*)',
    name: '404',
    component: NoFind,
  },
];
export default routes;
