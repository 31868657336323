import API from '@/config/api';
import BuriedPoint from './buried-point';
// 广告跳转统一的调这个方法
const publishAdvertJump = (router: any, data: any) => {
  const {
    jumpUrl, jumpForm, linked, flag, advertisingName, id, zoneName,
  } = data || {};
  const homeFlag = [1, 2, 3, 4, 5, 6];
  const myFlag = [10, 15];
  const adInfo = {
    adName: advertisingName,
    adId: id,
    channelTag: localStorage.channel,
  };
  if (homeFlag.includes(flag)) {
    BuriedPoint.click_homeAd(adInfo);
  } else if (myFlag.includes(flag)) {
    BuriedPoint.click_myAd(adInfo);
  }
  switch (jumpForm) {
    // case 'aliApplets':
    //   router.push(`${jumpUrl}`);
    //   break;
    case 'zone':
      router.push(`/zone/${linked}?zoneName=${zoneName}`);
      break;
    case 'goodsDetail':
      router.push(`/commodity_detail?goodsId=${linked}`);
      break;
    case 'H5':
      if (!jumpUrl) {
        return;
      }
      router.push(`${jumpUrl}`);
      break;
    default:
      if (!jumpUrl) {
        return;
      }
      window.location.href = jumpUrl;
      break;
  }
};
// 认证判断跳转 nocheck 表示确认下单默认跳转到输入文字实名认证
const verifiedJump = async (router: any, fn?: any, contactShow = true, nocheck = false) => {
  const {
    authStatus, faceStatus, contactStatus, handCardId,
  } = await API.getCurrentUser({});
  // 实名认证
  if (!authStatus) {
    // 判断是否要上传身份证
    if (handCardId || nocheck) {
      router.push('/verified');
    } else {
      router.push('/card_verified');
    }
  } else if (!faceStatus) {
    // 人脸识别
    /**
     * TENCENT - 腾讯云刷脸认证
     * ZHIMACREDIT - 支付宝刷脸认证
     * ESIGN - 快捷刷脸认证
     */
    const { authShortUrl } = await API.authFaceId({
      authRedirectUrl: `${window.location.origin}/#/my`,
      faceauthMode: 'TENCENT',
    });
    window.location.href = authShortUrl;
    return
  } else if (!contactStatus && contactShow) {
    // 紧急联系人
    router.push('/contact');
  } else if (fn) {
    fn();
  }
};
// 获取并设置channel
const setChannel  = () => {
  const { hash } = window.location;
  const reg = new RegExp('(^|\\?|&)channel=([^&]*)(\\s|&|$)', 'i');
  if (reg.test(hash)) {
    const channel = unescape(RegExp.$2.replace(/\+/g, ' '));
    console.log(channel);

    localStorage.setItem('channel', channel);
  }
};
export {
  publishAdvertJump,
  verifiedJump,
  setChannel,
};
