const DEFAULT_COMPILE_ENV = 'prod';
// 域名
const DefaultConfig: any = {
  dev: {
    baseUrl: '/dev',
    publicUrl: '/',
  },
  prod: {
    baseUrl: 'https://hasee.dingxiangzu.com',
    bucket: 'dxz-h5-prod',
    callbackUrl: 'https://h5pt.dingxiangzu.com',
  },
};
/** 图片地址 */
const IMGURL = {
  refactor: 'https://file.dingxiangzu.com/alipay/refactor/',
  public: 'https://file.dingxiangzu.com/alipay/public/',
  kzxImage: 'https://file.dingxiangzu.com/alipay/kzx-image/',
  freeEvent: 'https://file.dingxiangzu.com/alipay/free-event/',
};
export {
  IMGURL,
  DefaultConfig,
  DEFAULT_COMPILE_ENV,
};
