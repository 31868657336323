// 活动
const Spread = () => import('../views/activity/spread/index.vue');
const OneYuanRent = () => import('../views/activity/oneYuanRent/index.vue');

export default [
  {
    path: '/spread',
    name: '新机免押租',
    component: Spread,
  },
  {
    path: '/oneYuanRent',
    name: '首月1元 抢苹果热租手机',
    component: OneYuanRent,
  },
];
