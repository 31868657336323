const RentalOrder = () => import('../views/rentalOrder/index.vue');
const ReturnDetail = () => import('../views/returnDetail/index.vue');
const CheckDetail = () => import('../views/returnDetail/checkDetail.vue');

export default [
  {
    path: '/rentalOrder',
    name: '在租订单',
    component: RentalOrder,
  },
  {
    path: '/returnDetail',
    name: '还机详情',
    component: ReturnDetail
  },
  {
    path: '/checkDetail',
    name: '验机详情',
    component: CheckDetail
  },
];
