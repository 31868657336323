import API from '@/config/api';
export const actions = {
  // 设置loading
  setLoading({ commit }: any, status: number) {
    commit('SET_LOADING', status);
  },
  // 设置是否显示图形验证码
  setShowImgCode({ commit }: any, status: boolean) {
    commit('SET_IMGCODE', status);
  },
  // 获取地址列表
  setAddress({ commit }: any, status: Array<any>) {
    commit('SET_ADDRESS', status);
  },
  // 获取认证信息
  async setCertification({ commit }: any, status: any) {
    await API.getCurrentUser({}).then((res) => {
      if (res) {
        const newData: any = {};
        const tel = res.mobile || '';
        const name = res.realName || '';
        newData.desenMobile = `${tel.slice(0, 3)}****${tel.slice(-4)}`;
        newData.desenName = name.length === 2 ? `*${name.slice(-1)}` : `${name.slice(0, 1)}*${name.slice(-1)}`;
        commit('SET_CEREIFICATION', { ...res, ...newData });
      }
    });

  },
  // 获取登录图片信息
  setImgCodeData({ commit }: any, status: any) {
    commit('SET_IMGCODEDATA', status);
  },
};
