import { get, post } from '@/utils/axios';

interface APIProps {
  memberContactList: (params: any) => Promise<any>;
  memberverifyContract: (params: any) => Promise<any>;
  generateImage: (params: any) => Promise<any>;
  mobileLogin: (params: any) => Promise<any>;
  sendVerifyCode: (params: any) => Promise<any>;
  logout: (params: any) => Promise<any>;
  refreshToken: (params: any) => Promise<any>;
  addAddr: (params: any) => Promise<any>;
  deleteAddress: (params: any) => Promise<any>;
  getAddressDetail: (params: any) => Promise<any>;
  getMemberAddrList: (params: any) => Promise<any>;
  modifyAddr: (params: any) => Promise<any>;
  homeAdvert: (params: any) => Promise<any>;
  backingList: (params: any) => Promise<any>;
  billCount: (params: any) => Promise<any>;
  billDetail: (params: any) => Promise<any>;
  repayNowAmount: (params: any) => Promise<any>;
  repayNow: (params: any) => Promise<any>;
  billCreate: (params: any) => Promise<any>;
  usableVoucherList: (params: any) => Promise<any>;
  billList: (params: any) => Promise<any>;
  billOrderDetail: (params: any) => Promise<any>;
  processBill: (params: any) => Promise<any>;
  closePayment: (params: any) => Promise<any>;
  goodsByFlag: (params: any) => Promise<any>;
  userCouponList: (params: any) => Promise<any>;
  userCouponCount: (params: any) => Promise<any>;
  uploadAvatar: (params: any) => Promise<any>;
  getBindCardList: (params: any) => Promise<any>;
  getCardBin: (params: any) => Promise<any>;
  cardSign: (params: any) => Promise<any>;
  cardSendCode: (params: any) => Promise<any>;
  getCurrentUser: (params: any) => Promise<any>;
  getGoodsById: (params: any) => Promise<any>;
  goodsUsedVoucherList: (params: any) => Promise<any>
  receiveVoucher: (params: any) => Promise<any>
  getVoucherStatus: (params: any) => Promise<any>
  create: (params: any) => Promise<any>
  rentDetail: (params: any) => Promise<any>
  userOrderCouponList: (params: any) => Promise<any>
  amount: (params: any) => Promise<any>
  confirm: (params: any) => Promise<any>
  authRealName: (params: any) => Promise<any>
  authFaceId: (params: any) => Promise<any>
  syncAuthStatus: (params: any) => Promise<any>
  aliFaceAuthUrl: (params: any) => Promise<any>
  aliFaceAuthCallback: (params: any) => Promise<any>
  ocrScan: (params: any) => Promise<any>
  oss: (params: any) => Promise<any>
  queryMemberAuthInfo: (params: any) => Promise<any>
  getAuthInfo: (params: any) => Promise<any>
  orderList: (params: any) => Promise<any>
  payMoney: (params: any) => Promise<any>
  delivery: (params: any) => Promise<any>
  cancel: (params: any) => Promise<any>
  submitRisk: (params: any) => Promise<any>
  orderDetail: (params: any) => Promise<any>
  confirmInfo: (params: any) => Promise<any>
  confirmAmount: (params: any) => Promise<any>
  billConfirm: (params: any) => Promise<any>;
  zoneGoodsList: (params: any) => Promise<any>;
  getUserAuthentication: (params: any) => Promise<any>;
  payResult: (params: any) => Promise<any>;
  paymentResult: (params: any) => Promise<any>;
  queryProtocolUrl: (params: any) => Promise<any>;
  getCategoryTabByParam: (params: any) => Promise<any>
  getGoodsListByCategoryAndBrand: (params: any) => Promise<any>
  getGoodsListByCategoryTab: (params: any) => Promise<any>
  sendCollectMsg: (params: any) => Promise<any>
  queryGoods: (params: any) => Promise<any>
  ossVideo: (params: any) => Promise<any>
  deleteVideo: (params: any) => Promise<any>
  getByOrderNo: (params: any) => Promise<any>
  updateAuditData: (params: any) => Promise<any>
  getInfo: (params: any) => Promise<any>
  sendBindCode: (params: any) => Promise<any>
  authCard: (params: any) => Promise<any>
  confirmBinding: (params: any) => Promise<any>
  getActivityBase: (params: any) => Promise<any>
  getReceiveVoucherCount: (params: any) => Promise<any>
  multiLogin: (params: any) => Promise<any>
  orderListV3: (params: any) => Promise<any>
  payment: (params: any) => Promise<any>
  returnInfo: (params: any) => Promise<any>
  creatReturn: (params: any) => Promise<any>
  returnDetail: (params: any) => Promise<any>
  checkDetail: (params: any) => Promise<any>
  supplement: (params: any) => Promise<any>
}
const API: APIProps = {
  // 获取紧急联系人列表
  memberContactList: (params: any) => get('/hasee/nokia/memberContact/memberContactList', params),
  // 添加紧急联系人
  memberverifyContract: (params: any) => post('/hasee/nokia/memberContact/verifyMemberContract', params),
  // 登录获取图片验证码
  generateImage: (params: any) => post('/hasee/auth/generateImage', params),
  // 手机登录
  mobileLogin: (params: any) => post('/hasee/auth/mobileCodeLogin', params),
  // 发送短信验证码
  sendVerifyCode: (params: any) => post('/hasee/auth/sendMobileVerifyCode', params),
  // 登出
  logout: (params: any) => post('/hasee/auth/logout', params),
  // 刷新用户token
  refreshToken: (params: any) => post('/hasee/auth/refreshToken', params),
  // 首页广告位
  homeAdvert: (params: any) => post('/hasee/vivo/zone/query/homePageAdconfig', { ...params, ...{ device: 'H5', channelNo: localStorage.channel } }),

  // 上传头像
  uploadAvatar: (params: any) => post('/hasee/nokia/member/uploadAvatar', params),
  // 添加地址
  addAddr: (params: any) => post('/hasee/nokia/memberAddr/addAddr', params),
  // 获取地址详情
  getAddressDetail: (params: any) => post('/hasee/nokia/memberAddr/getByAddressById', params),
  // 删除地址
  deleteAddress: (params: any) => post('/hasee/nokia/memberAddr/deleteAddress', params),
  // 获取地址列表吧
  getMemberAddrList: (params: any) => post('/hasee/nokia/memberAddr/getMemberAddrList', params),
  // 修改地址
  modifyAddr: (params: any) => post('/hasee/nokia/memberAddr/modifyAddr', params),
  // 我的账单列表
  billList: (params: any) => post('/hasee/apple/repayV2/info', params),
  // 我的账单承接页列表
  backingList: (params: any) => get('/hasee/apple/repayV2/backingList', params),
  // 单个账单详情
  billDetail: (params: any) => post('/hasee/apple/repayV2/issue', params),
  // 单个账单详情商品信息、订单基本信息接口
  billOrderDetail: (params: any) => get('/hasee/oppo/orderV2/info', params),
  // 获取账单的个数
  billCount: (params: any) => get('/hasee/apple/repayV2/backingCount', params),
  // 我的立即还款金额查询
  repayNowAmount: (params: any) => post('/hasee/apple/repayV2/repayNowAmount', params),
  // 立即还款
  repayNow: (params: any) => post('/hasee/apple/repayV2/repayNow', params),
  // 创建还款单
  billCreate: (params: any) => post('/hasee/apple/repayV2/create', params),
  // 查询是否有未支付的订单
  processBill: (params: any) => post('/hasee/apple/repayV2/processBill', params),
  // 创建还款单
  getUserAuthentication: (params: any) => post('/hasee/nokia/member/getMemberLiteById', params),
  // 还款买断优惠券列表
  usableVoucherList: (params: any) => post('/hasee/apple/repayV2/usableVoucherList', params),
  // 查询商品推荐广告位列表
  goodsByFlag: (params: any) => get(
    `/hasee/vivo/zone/goodsByFlag/${params.flag}/H5/${params.page}/${params.pageSize}`,
    {},
  ),
  // 查询商品专区列表
  zoneGoodsList: (params: any) => get(
    `/hasee/vivo/zone/goods/${params.page}/${params.zoneId}/${params.pageSize}`,
    {},
  ),
  // 优惠券列表
  userCouponList: (params: any) => post('/hasee/vivo/voucher/userCouponList', params),
  // 我的页面-优惠券个数
  userCouponCount: (params: any) => get('/hasee/vivo/voucher/unUsedVoucherCount', params),
  // 验证卡bin
  getCardBin: (params: any) => get('/hasee/bifrost/signCard/getCardBin', params),
  // 确认绑卡
  cardSign: (params: any) => post('/hasee/bifrost/signCard/sign', params),
  // 发送验证码
  cardSendCode: (params: any) => post('/hasee/bifrost/signCard/send', params),

  // 商品详情
  getGoodsById: (params: any) => get('/hasee/honor/goods/getGoodsById', params),
  // 商品详情优惠券
  goodsUsedVoucherList: (params: any) => post('/hasee/vivo/voucher/goodsUsedVoucherList', params),
  // 领取优惠券
  receiveVoucher: (params: any) => post('/hasee/vivo/voucher/receiveVoucher', params),
  // 获取优惠券使用状态
  getVoucherStatus: (params: any) => post('/hasee/vivo/voucher/receivedByRuleIds', params),
  // 新建租赁申请单
  create: (params: any) => post('/hasee/sony/rent/create', params),
  // 租赁申请单详情
  rentDetail: (params: any) => post('/hasee/h5/sony/rent/detail', params),
  // 查询用户下单符合要求的优惠券列表
  userOrderCouponList: (params: any) => post('/hasee/vivo/voucher/userOrderCouponList', params),
  // 金额计算
  amount: (params: any) => post('/hasee/h5/sony/payProcess/amount', params),
  // 确认下单
  confirm: (params: any) => post('/hasee/h5/sony/rent/confirm', params),
  // 实名认证
  authRealName: (params: any) => post('/hasee/nokia/memberAuthInfo/authRealName', params),
  // 实名认证
  authFaceId: (params: any) => post('/hasee/nokia/member/esignFaceAuthUrl', params),
  // 同步实名状态
  syncAuthStatus: (params: any) => get('/hasee/nokia/member/getEsignFaceAuth', params),
  // 获取用户简要信息
  getCurrentUser: (params: any) => post('/hasee/nokia/member/getCurrentUser', params),
  // 获取支付宝人脸校验链接
  aliFaceAuthUrl: (params: any) => post('/hasee/nokia/member/aliFaceAuthUrl', params),
  // 人脸成功回调接口(第三方调用)
  aliFaceAuthCallback: (params: any) => get('/hasee/nokia/member/aliFaceAuthCallback', params),
  // ocr认证
  ocrScan: (params: any) => post('/hasee/nokia/idcard/ocrScan', params),
  // 图片上传
  oss: (params: any) => post('/hasee/common/upload/oss', params),
  // 查询客户认证信息
  getAuthInfo: (params: any) => post('/hasee/nokia/memberAuthInfo/getAuthInfo', params),
  // 查询客户ocr认证信息
  queryMemberAuthInfo: (params: any) => post('/hasee/nokia/memberAuthInfo/queryMemberAuthInfo', params),
  // 订单(申请单)列表
  orderList: (params: any) => post('/hasee/h5/sony/order/list', params),
  // H5绑卡相关 , h5绑卡相关接口
  getBindCardList: (params: any) => get('/hasee/bifrost/signCard/getBindCardList', params),
  //  h5支付
  payMoney: (params: any) => post('/hasee/h5/sony/pay/payMoney', params),
  //  确认收货
  delivery: (params: any) => post('/hasee/h5/sony/order/delivery', params),
  //  取消订单
  cancel: (params: any) => post('/hasee/h5/sony/order/cancel', params),
  // 提交风控
  submitRisk: (params: any) => get('/hasee/h5/sony/rent/submitRisk', params),
  // 租赁申请单详情
  orderDetail: (params: any) => post('/hasee/h5/sony/rent/detail', params),
  // 确认还款详情
  confirmInfo: (params: any) => post('/hasee/apple/repayV2/confirmInfo', params),
  // 确认还款金额计算
  confirmAmount: (params: any) => post('/hasee/apple/repayV2/confirmAmount', params),
  // 确认还款，跳转收银台
  billConfirm: (params: any) => post('/hasee/apple/repayV2/confirm', params),
  // 确认还款，跳转收银台
  payResult: (params: any) => post('/hasee/h5/sony/pay/payResult', params),
  paymentResult: (params: any) => post('/hasee/sony/cashier/paymentResult', params),
  // 查询订单的协议地址
  queryProtocolUrl: (params: any) => post('/hasee/h5/sony/order/queryProtocolUrl', params),
  // 首页分类tab
  getCategoryTabByParam: (params: any) => get('/hasee/honor/categoryTab/getCategoryTabByTenant', params),
  // 首页分类tab商品列表
  getGoodsListByCategoryTab: (params: any) => post('/hasee/honor/categoryTab/getGoodsListByCategoryTab', params),
  getGoodsListByCategoryAndBrand: (params: any) => post('/hasee/honor/goods/getGoodsListByCategoryAndBrand', params),
  // 发送催审通知
  sendCollectMsg: (params: any) => get('/hasee/h5/sony/order/sendCollectMsg', params),
  // 分类接口推荐
  queryGoods: (params: any) => get('/hasee/honor/categoryTab/recommend/queryGoods', params),
  // 上传视频
  ossVideo: (params: any) => post('/hasee/common/upload/video', params),
  // 删除视频
  deleteVideo: (params: any) => get('/hasee/common/upload/deleteOssVideo', params),
  // 获取用户审核信息
  getByOrderNo: (params: any) => get('/hasee/h5/sony/auditUserData/getByOrderNo', params),
  // 更新用户审核信息
  updateAuditData: (params: any) => post('/hasee/h5/sony/auditUserData/updateAuditData', params),
  // 获取用户信息
  getInfo: (params: any) => post('/hasee/bifrost/noLoginSignCard/getAuthInfo', params),
  // 发送绑卡短信（度小满）
  sendBindCode: (params: any) => post('/hasee/bifrost/noLoginSignCard/send', params),
  authCard: (params: any) => post('/hasee/bifrost/signCard/authCard', params),
  // 确认绑卡（度小满）
  confirmBinding: (params: any) => post('/hasee/bifrost/noLoginSignCard/sign', params),
  // 获取活动配置信息
  getActivityBase: (params: any) => get('/hasee/vivo/activity/getActivityBase', params),
  // 获取优惠券可以领取的数量
  getReceiveVoucherCount: (params: any) => get('/hasee/vivo/voucher/getReceiveVoucherCount', params),
  // 获取优惠券是否已经领取过


  // 订单列表
  orderListV3: (params: any) => post('/hasee/h5/sony/order/list/v3', params),
  // 多账号登录
  multiLogin: (params: any) => post('/hasee/auth/multiLogin', params),
  // 支付 (新)
  payment: (params: any) => post('/hasee/sony/cashier/payment', params),
  // 取消未支付的订单
  closePayment: (params: any) => post('/hasee/sony/cashier/closePayment', params),
  // 待归还
  returnInfo: (params: any) => get('/hasee/sony/orderReturn/returnInfo', params),
  // 创建还机单
  creatReturn: (params: any) => post('/hasee/sony/orderReturn/createOrderReturn', params),
  // 还机单详情
  returnDetail: (params: any) => get('/hasee/sony/orderReturn/queryReturnDetailById', params),
  // 验机单详情
  checkDetail: (params: any) => get('/hasee/sony/orderReturn/queryInspectDetailById', params),
  // 补充租金
  supplement: (params: any) => post('/hasee/sony/rent/payment', params),
};
export default API;
