// 订单列表
const OrderList = () => import('@user/order_list/index.vue');
// 订单详情
const OrderDetail = () => import('@user/order_detail/index.vue');
// 身份证认证
const CardVerified = () => import('@user/card_verified/index.vue');
// 我要买断
const Buyout = () => import('@user/buyout/index.vue');
// 租机流程
const Procedure = () => import('@user/procedure/index.vue');
// 常见问题
const Question = () => import('@user/question/index.vue');
// 供应商入驻
const Supplier = () => import('@user/supplier/index.vue');
// 意见反馈
const Feedback = () => import('@user/feedback/index.vue');
// 设置
const Setting = () => import('@user/setting/index.vue');

const MyRouter = [
  {
    path: '/order_list',
    name: '订单列表',
    component: OrderList,
  },
  {
    path: '/order_detail',
    name: '订单详情',
    component: OrderDetail,
  },
  {
    path: '/card_verified',
    name: '身份证实名认证',
    component: CardVerified,
  },
  {
    path: '/buyout',
    name: '我要还款',
    component: Buyout,
  },
  {
    path: '/procedure',
    name: '租机流程',
    component: Procedure,
  },
  {
    path: '/question',
    name: '常见问题',
    component: Question,
  },
  {
    path: '/supplier',
    name: '供应商入驻',
    component: Supplier,
  },
  {
    path: '/feedback',
    name: '意见反馈',
    component: Feedback,
  },
  {
    path: '/setting',
    name: '设置',
    component: Setting,
  },
];

export default MyRouter;
